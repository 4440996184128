import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { useIntl } from "gatsby-plugin-intl"
import Seo from "../components/seo"

const About = ({data}) => {
  const { allFile } = data

  const intl = useIntl()
  return (
  <Layout>
    <Seo title={intl.formatMessage({id: "about_title"})}/>
    <h6> ‎ </h6>
    <h6> ‎ </h6>
    <p>
      {intl.formatMessage({id: "about_text1"})}
    </p>
    <p>
      {intl.formatMessage({id: "about_text2"})}
    </p>
    <ul>
      <li>
        <b>{intl.formatMessage({id: "about_name1"})}</b>
        {intl.formatMessage({id: "about_text3"})}
      </li>
      <li>
        <b>{intl.formatMessage({id: "about_name2"})}</b>
        {intl.formatMessage({id: "about_text4"})}
      </li>
      <li>
        <b>{intl.formatMessage({id: "about_name3"})}</b>
        {intl.formatMessage({id: "about_text5"})}
      </li>
      <li>
        <b>{intl.formatMessage({id: "about_name4"})}</b>
        {intl.formatMessage({id: "about_text6"})}
      </li>
      <li>
        <b>{intl.formatMessage({id: "about_name5"})}</b>
        {intl.formatMessage({id: "about_text7"})}
      </li>
    </ul>
    <p>{intl.formatMessage({id: "about_text8"})}</p>
    <h6> ‎</h6>
    <p>{intl.formatMessage({id: "about_ge1"})}</p>
    <p>{intl.formatMessage({id: "about_ge2"})}</p>
    <p>{intl.formatMessage({id: "about_ge3"})}</p>
    <p>{intl.formatMessage({id: "about_ge4"})}<a href="mailto:info@georges-enesco.org">info@georges-enesco.org</a></p>
  <h6>‎ </h6>
    <Img fluid={allFile.edges[0].node.childImageSharp.fluid}/>
    <h6 style={{textAlign: 'center', color: '#000'}}>
      {intl.formatMessage({id: "about_text9"})}
    </h6>
    <h6 style={{textAlign: 'center', color: '#000'}}>
      {intl.formatMessage({id: "about_text10"})}
    </h6>
  </Layout>
    )
}

export const pageQuery = graphql`
    query MyQuery {
        allFile(filter: {name: {eq: "image1"}}) {
            edges {
                node {
                    childImageSharp {
                        fluid(maxHeight: 600) {
                        ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    }

`
export default About
